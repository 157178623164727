var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"edc9b8296d769462ab6b156b69bfa7aff5199caa"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://88c2caf6ed1f59a50235eaa45c54b30d@o4507056239607808.ingest.de.sentry.io/4507147707482193',
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'core.api.drdropin.no',
        'staging-core.api.drdropin.no',
        /^\//,
      ],
    }),
    new posthog.SentryIntegration(
      posthog,
      'https://sentry.drdropin.com/organizations/drdropin/projects/',
      4,
    ),
  ],
  environment: process.env.NODE_ENV,
});
